(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller.VipUploadCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('VipUploadCtrl', VipUploadCtrl);

  function VipUploadCtrl(appConfig,
                         $state,
                         $scope,
                         FormControl,
                         $q,
                         $translate,
                         $analytics,
                         LoggedUser,
                         OrderService,
                         Order,
                         _) {
    var vm = this
      , formControl = new FormControl()
      , deferred
      , availableFile = {name: '', valid: false, path: ''};

    vm.fileRelatedError = false;
    vm.note = '';

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      var user = LoggedUser.getUser()
        , company = LoggedUser.getCompany();

      $flow.opts.target = appConfig.api.url + 'users/' + user.id + '/companies/' + company.id + '/orders/vip/files';
      $flow.upload();

      if (!vm.fileRelatedError) {
        deferred = $q.defer();
        formControl.process(deferred.promise);
      }
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /*jshint unused:false */
      /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      availableFile.name = $files.name;
      availableFile.valid = false;
      availableFile.path = '';
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      vm.fileRelatedError = !_.isUndefined(response.data.error.file) ? Object.keys(response.data.error.file) : false;
      vm.error = response.data.error;
      deferred.reject(response);
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow) {
      /*jshint unused:false */
      /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      availableFile.path = response.flowRelativePath;
      deferred.resolve();
    };

    vm.hasFile = function hasFile() {
      return !_.isEmpty(availableFile.name);
    };

    vm.isValidFile = function isValidFile() {
      return availableFile.valid;
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.getAvailableFileName = function getAvailableFileName() {
      return availableFile.name;
    };

    vm.getFileRelatedError = function getFileRelatedError() {
      return vm.fileRelatedError;
    };

    vm.validateFile = function validateFile($file, $event, $flow) {
      /*jshint unused:false */
      /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      var sizeLimit = 35 * 1024 * 1000
        , valid = $file.size < sizeLimit;

      vm.fileRelatedError = false;

      if (!valid) {
        vm.fileRelatedError = 'fileSize';
      }

      return valid;
    };

    vm.submit = function submit() {
      var user = LoggedUser.getUser()
        , company = LoggedUser.getCompany()
        , dataToSend = {
          id: user.id,
          companyId: company.id,
          fileName: availableFile.path,
          note: vm.note
        };

      formControl
        .process(Order.vip(dataToSend).$promise)
        .then(function () {
          var action = $translate.instant('order.steps.analytics.vipUpload.authenticated') + ' - ' + $translate.instant('order.steps.analytics.ended')
            , analyticsDatas = {
              category: $translate.instant('order.steps.analytics.registration.3'),
              action: action,
              label: company.vatNumber
            };

          $analytics.eventTrack(action, analyticsDatas);

          OrderService.setSuccessData({
            isVip: true
          });

          $state.go('success', {type: 'vip'});
        });
    };

    // condition is removed on 2018/05/22 due to #28723
    //$scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    function onStateChangeSuccess() {
      var company = LoggedUser.getCompany();

      if (!company.isVIP) {
        $state.go('dashboard')
      }
    }
  }
}());
